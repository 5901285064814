import PhotocopyPage from './photocopy/pages/PhotocopyPage'
export { PhotocopyPage }

export * from './404'
export * from './account'
export * from './admin'
export * from './bicycle'
export * from './bus'
export * from './coupon'
export * from './driving-license'
export * from './guest-house'
export * from './guide'
export * from './health'
export * from './home'
export * from './job'
export * from './login'
export * from './maintainance'
export * from './mbbank'
export * from './profile'
export * from './qr-scan'
export * from './swimming-pool'
export * from './uniform'
export * from './support'
export const ROLE = {
    USER: 0,
    ADMIN: 1,

    DRIVING: {
        ADMIN: 10,
        MANAGER: 11,
        LANGF: 12,
        UEL: 13,
        UIT: 14,
        CNTD: 17,
    },

    UNIFORM: {
        ADMIN: 3,
    },

    REAL_ESTATE: {
        ADMIN: 4,
    },

    STORE: {
        ADMIN: 5,
    },
}
